<template>
	<div id="box">
		<div class="box">
			<div class="top">
				<div class="topleft">
					<talentgauge></talentgauge>
				</div>
				<div class="topright">
					<talentpie></talentpie>
				</div>
			</div>
			<div class="bom">
				<talentbar></talentbar>
			</div>
		</div>
	</div>
</template>

<script>
	import talentgauge from "@/components/talents/gauge"
	import talentpie from "@/components/talents/talentspie"
	import talentbar from "@/components/talents/talentbar"
	export default {
		components:{
			talentgauge,
			talentpie,
			talentbar
		}
	}
</script>

<style lang="less" scoped="scoped">
	.box{
		width: 100%;
		min-height:600px ;
		background-color: #f2f3f5;
		border-radius: 4px;
	}
	.top{
		width: 100%;
		height: 300px;
		display: flex;
		align-items: center;
		.topleft{
			width: 59%;
			height: 100%;
		}
		.topright{
			width: 40%;
			height: 100%;
			margin-left: auto;
		}
	}
	.bom{
		width: 100%;
		height: 450px;
		margin-top: 20px;
	}
</style>
