<template>
	<div class="boxx">
		<h1>学历分布</h1>
		<div class="gauge">
			<div class="gaugeitem">
				<el-progress :stroke-width='16' type="circle" :percentage="75"></el-progress>
				<p>初高中<span>7500人</span></p>
			</div>
			<div class="gaugeitem">
				<el-progress :stroke-width='16' type="circle" :percentage="19"></el-progress>
				<p>专科<span>1900人</span></p>
			</div>
			<div class="gaugeitem">
				<el-progress :stroke-width='16' type="circle" :percentage="5"></el-progress>
				<p>本科<span>500人</span></p>
			</div>
			<div class="gaugeitem">
				<el-progress :stroke-width='16' type="circle" :percentage="1"></el-progress>
				<p>硕士及以上<span>100人</span></p>
			</div>
		</div>
	</div>
</template>

<script>
	export default {

	}
</script>

<style scoped="scoped" lang="less">
	.boxx{
		width: 100%;
		height: 100%;
		background-color: #FFFFFF;
		border-radius: 4px;
		padding: 4px;
		.gauge{
			margin-top: 40px;
			padding: 20px 40px;
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
			.gaugeitem{
				p{
					font-size: 14px;
					margin-top: 10px;
					text-align: center;
					span{
						margin-left: 4px;
					}
				}

			}
			.gaugeitem/deep/.el-progress__text{
				font-size: 20px !important;
				font-weight: 600;
				color: #000000;
			}
		}
	}
</style>
