<template>
	<div class="boxx">
		<div class="pie" ref='pie'></div>
	</div>
</template>

<script>
	export default {
		mounted() {
			var box= this.$refs.pie
			this.chart(box)
		},
		methods:{
			chart(box){
				var myChart = this.$echarts.init(box);
				var option;

				option = {
				    title: {
				        text: '技能分布',
						textStyle:{
							color:"#333333",
							fontSize: 18,
						}
				    },
					color:["#1492FF","#7bfe76","#F2972D","#622df2","#FE768E"],
				    tooltip: {
				        trigger: 'item'
				    },
				    legend: {
				        orient: 'vertical',
				        left: 'right',
				    },
				    series: [
				        {
				            name: '技能分布',
				            type: 'pie',
				            radius: '70%',
							label: {
								show: true,
								position: 'inside',
								formatter:'{b}\n{d}%',
								fontSize:'14',
							},
				            data: [
				                {value: 1048, name: '一级技工'},
                      {value: 800, name: '二级技工'},
                      {value: 600, name: '三级技工'},
                      {value: 400, name: '四级技工'},
				                {value: 100, name: '技师'},
				            ],
				            emphasis: {
				                itemStyle: {
				                    shadowBlur: 10,
				                    shadowOffsetX: 0,
				                    shadowColor: 'rgba(0, 0, 0, 0.5)'
				                }
				            }
				        }
				    ]
				};

				option && myChart.setOption(option);
			}
		}
	}
</script>

<style lang="less" scoped="scoped">
	.boxx{
		width: 100%;
		height: 100%;
		.pie{
			width: 100%;
			height: 100%;
			background-color: #FFFFFF;
			border-radius: 4px;
		}
	}
</style>
