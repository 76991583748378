<template>
	<div class="boxx">
		<div ref="bar" class="bar"></div>
	</div>
</template>

<script>
	export default {
		mounted() {
			var box =this.$refs.bar
			this.chart(box)
		},
		methods:{
			chart(box){
				var myChart = this.$echarts.init(box);
				var option;
        var colors = ['#5470C6', '#91CC75', '#EE6666'];
				option = {
          color: colors,
				    title: {
				        text: '人才分布榜'
				    },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              crossStyle: {
                color: '#999'
              }
            }
          },

				    legend: {
				        data: ['技师', '技工', '本科及以上'],
						left: 'right',
				    },
				    grid: {
				        left: '3%',
				        right: '4%',
				        bottom: '5%',
				        containLabel: true
				    },
          xAxis: [
            {
              type: 'category',
              data: ['金田工业园', '仪帮工业园', '曙光工业园', '天成小微园', '新雅工业园区', '黄河轻工业园', '新双鲸产业园','彩虹创业园','世纪创业园','龙诚创业园','新城小微园']
              ,axisPointer: {
                type: 'shadow'
              }
            }
          ],
				    yAxis: [
              {
                type: 'value',
                min: 0,
                interval: 50,
                axisLabel: {
                  formatter: '{value} 人'
                }
              }],
					dataZoom:[
						{
							type:'slider',
							xAxisIndex:0,
							bottom: 0,
							height:12,
							showDetail: false,
							startValue: 0,
							endValue:8,
							handleSize: '110%',
							handleStyle:{
								color:"#d3dee5",
							},
							textStyle:{
								color:"#fff"
							},
							borderColor:"#90979c"
						},
					],
				    series: [
				        {
				            name: '技师',
				            type: 'bar',
                  label: {
                    show: true,
                    position: "top"
                  },
				            data: [120, 132, 101, 134, 90, 230, 210,211,452,741,963]
				        },
				        {
				            name: '技工',
				            type: 'bar',
                  label: {
                    show: true,
                    position: "top"
                  },
				            data: [220, 182, 191, 234, 290, 330, 310,123,456,789,456]
				        },
				        {
				            name: '本科及以上',
				            type: 'line',
                  label: {
                    show: true,
                    position: "top"
                  },
				            data: [150, 232, 201, 154, 190, 330, 210,387,454,321,205]
				        },
				    ]
				};

				option && myChart.setOption(option);

			}
		}
	}
</script>

<style lang="less"	scoped="scoped">
	.boxx{
		width: 100%;
		height: 100%;
		.bar{
			width: 100%;
			height: 100%;
			background-color: #FFFFFF;
			border-radius: 4px;
		}
	}
</style>
